import React from "react";

import "./notifications.bootstrap.scss";

const PersonalizationMessageBar = () => {
  return (
    <div
      id="personalization-bar"
      className="bg-info"
      style={{ minHeight: "40px", zIndex: "200", position: "relative" }}
    >
      <div id="personalization-message" className="container text-white personalization-text py-2"></div>
    </div>
  );
};
export default PersonalizationMessageBar;
