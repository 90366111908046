import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

import Icon from "../custom-widgets/icon";

import styles from "./personalization-cards.module.scss";

/**
 * @typedef {Object} Cards
 * @property {string} id - Required string id of the card.
 * @property {number} cardNumber - required number of the card.
 * @property {GatsbyImageData} imgData - optional Gatsby image data.
 * @property {string} altText - optional string image alt text.
 * @property {string} heading - optional green h3 title text of the card.
 * @property {string} subheading - optional bold h5 title text of the card.
 * @property {string} text - optional text description below the heading and subheading of the card.
 * @property {string} tertiaryText - optional text small at the bottom of the card.
 * @property {string} linkId - required link id string.
 * @property {string} linkUrl - required link string where to go like "/personal-banking".
 * @property {boolean} externalLink - optional boolean to use Link if false or undefined or an anchor element if true.
 * @property {boolean} internalPage - optional boolean to use internalPage ? "_self" : "_blank" when externalLink is true.
 */

/**
 * This is the component to render the cd cards in English or Spanish.
 *
 * NOTE: please do NOT change the margin-bottom classes on these cards, it should be "mb-3 mb-lg-4".
 * Do NOT override the spacing using padding or margin classes on the containerClass.
 * If you need to override the section padding because the cards are connected
 * to other sections that need to look like one section, pass the sectionClass
 * "section-padding-top", "section-padding-none", "section-padding-bottom".
 * 
 * @param {Object} props - The object containing props.
 * @param {string} props.containerClass - Classes for div container of the cards when defaultCardsArray length is greater than 1.
 * @param {number[]} props.defaultCardsArray - you can arrange the cards with this array of numbers ordering the cardNumber of each card for example [3,1,2].
 * @param {Cards[]} props.cardData - array of objects with the data for each card.
 *   - `id`: required string id of the card.
 *   - `cardNumber`: required number of the card.
 *   - `imgData`: optional Gatsby image data.
 *   - `altText`: optional string image alt text.
 *   - `heading`: optional green h3 title text of the card.
 *   - `subheading`: optional bold h5 title text of the card.
 *   - `text`: optional text description below the heading and subheading of the card.
 *   - `tertiaryText`: optional text small at the bottom of the card.
 *   - `linkId`: required link id string.
 *   - `linkUrl`: required link string where to go like "/personal-banking".
 *   - `externalLink`: optional boolean to use Link if false or undefined or an anchor element if true.
 *   - `internalPage`: optional boolean to use internalPage ? "_self" : "_blank" when externalLink is true.
 * @returns {React.JSX.Element} Returns the JSX component to render.
 */
const PersonalizationCards = ({
  containerClass = "bg-info",
  defaultCardsArray = [1, 1, 1],
  cardData: propCardData = null
}) => {
  const imgData = useStaticQuery(graphql`
    {
      card1ImageVar: file(relativePath: { eq: "cards/personalization/thumbnail-personal-credit-card-112624.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card2ImageVar: file(relativePath: { eq: "cards/personalization/thumbnail-interest-checking-112624.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card3ImageVar: file(relativePath: { eq: "cards/personalization/thumbnail-smart-start-112624.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  /*
    NOTE: headings (Title) should be limited to 50 characters, and text (description) limited to 95 characters.
    as per design in Figma requirements.
  */
  const defaultCardData = [
    {
      // CARD SLOT 1
      slotNumber: 1,
      cards: [
        {
          cardNumber: 1,
          id: "home-personalization-card-1-default",
          imgData: imgData.card1ImageVar.childImageSharp.gatsbyImageData,
          altText: "Woman making a mobile purchase with a WaFd Bank credit card.",
          heading: "Save on Holiday Shopping with up to 2% Cashback!",
          subheading: null,
          text: "Maximize your savings with a Best Bank.",
          linkUrl: "/personal-banking/credit-cards"
        }
      ]
    },
    {
      // CARD SLOT 2
      slotNumber: 2,
      cards: [
        {
          cardNumber: 1,
          id: "home-personalization-card-2-default",
          imgData: imgData.card2ImageVar.childImageSharp.gatsbyImageData,
          altText: "Couple laughing while cooking in their kitchen.",
          heading: "Get The Checking Account with Interest and Rewards",
          subheading: null,
          text: "Dark web monitoring, buyer's protection and so much more.",
          linkUrl: "/personal-banking/checking-account/interest-rewards-checking"
        }
      ]
    },
    {
      // CARD SLOT 3
      slotNumber: 3,
      cards: [
        {
          cardNumber: 1,
          id: "home-personalization-card-3-smart-start",
          imgData: imgData.card3ImageVar.childImageSharp.gatsbyImageData,
          altText: "A young family meeting a realtor in front of a new home.",
          heading: "With Smart Start, Your Dream House is within Reach",
          subheading: null,
          text: "See if you qualify and begin your home buying journey.",
          linkUrl: "/personal-banking/home-loans/smart-start-down-payment-assistance"
        }
      ]
    }
  ];
  // Use Props cardData if found, else use the default (used here due to imageVariable being used on the cards).
  const cardData = propCardData || defaultCardData;

  // NOTE: the slotCards and showHideClass logic below is for local build testing only, we can remove this show/hide logic once Tealium is handling that.
  const slotCards = defaultCardsArray; // show the defaults for now
  // defaultCardsArray picks the first card from each slot i.e., [1,1,1]
  const firstCard = cardData[0].cards[0];

  return (
    <>
      {slotCards.length === 1 ? (
        <div
          id={firstCard.id}
          key={firstCard.id}
          tabIndex={0}
          className={`card mb-3 mb-lg-4 overflow-hidden border-radius-12 border position-relative ${styles.personalizationCard}`}
        >
          {firstCard.imgData && (
            <GatsbyImage
              className={`card-image-top border-radius-bottom-0`}
              image={firstCard.imgData}
              alt={firstCard.altText}
            />
          )}
          <div className="card-body">
            {firstCard.heading && (
              <h3 className="text-success font-weight-semi-bold" style={{ textDecoration: "none" }}>
                {firstCard.heading}
              </h3>
            )}
            {firstCard.subheading && <h5 className="text-black font-weight-semi-bold">{firstCard.subheading}</h5>}
            {firstCard.text && (
              <p className={`mb-0 ${styles.cardDescription}`}>
                {firstCard.text}
                <Icon name="arrow-right" class="ml-1 text-primary" />
              </p>
            )}
          </div>
          {firstCard.linkUrl && firstCard.externalLink ? (
            <a
              href={firstCard.linkUrl}
              id={`${firstCard.id}-link`}
              className="stretched-link"
              rel="noopener noreferrer"
              target="_blank"
              aria-label={firstCard.heading}
            >
              <span className="sr-only">{firstCard.heading}</span>
            </a>
          ) : (
            <Link className="stretched-link" to={firstCard.linkUrl} aria-label={firstCard.heading}>
              <span className="sr-only">{firstCard.heading}</span>
            </Link>
          )}
        </div>
      ) : (
        <div id="homepage-personalization-cards" className={`personalization-cards ${containerClass}`}>
          <div className="container">
            <ul className="row mb-0 pl-0 list-unstyled row-cols-1 row-cols-md-2 row-cols-lg-3">
              {cardData.map((slot, slotIndex) => {
                return (
                  <li className="col mb-3 mb-lg-4 " key={slot.slotNumber}>
                    <div id={`home-personalization-card-slot-${slot.slotNumber}`} className="h-100 ">
                      {slot.cards.map((card) => {
                        let showHideClass =
                          slotIndex + 1 === slot.slotNumber && card.cardNumber === slotCards[slotIndex]
                            ? "d-block"
                            : "d-none";
                        return (
                          <div
                            id={card.id}
                            key={card.id}
                            tabIndex={0}
                            className={`card h-100 overflow-hidden border-radius-12 position-relative ${showHideClass} ${styles.personalizationCard} `}
                          >
                            {card.imgData && (
                              <GatsbyImage
                                className={`card-image-top border-radius-bottom-0`}
                                image={card.imgData}
                                alt={card.altText}
                              />
                            )}
                            <div className="card-body">
                              {card.heading && (
                                <h3 className="text-success font-weight-semi-bold" style={{ textDecoration: "none" }}>
                                  {card.heading}
                                </h3>
                              )}
                              {card.subheading && (
                                <h5 className="text-black font-weight-semi-bold">{card.subheading}</h5>
                              )}
                              {card.text && (
                                <p className={`mb-0 ${styles.cardDescription}`}>
                                  {card.text}
                                  <Icon name="arrow-right" class="ml-1 text-primary" />
                                </p>
                              )}
                              {card.tertiaryText && <p className="mb-0 mt-3 text-sm">{card.tertiaryText}</p>}
                            </div>
                            {card.linkUrl && card.externalLink ? (
                              <a
                                href={card.linkUrl}
                                id={card.linkId || `${card.id}-link`}
                                className="stretched-link"
                                rel={card.internalPage ? "" : "noopener noreferrer"}
                                target={card.internalPage ? "_self" : "_blank"}
                                aria-label={card.heading}
                              >
                                <span className="sr-only">{card.heading}</span>
                              </a>
                            ) : (
                              <Link
                                className="stretched-link"
                                to={card.linkUrl}
                                aria-label={card.heading}
                                id={card.linkId || `${card.id}-link`}
                              >
                                <span className="sr-only">{card.heading}</span>
                              </Link>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default PersonalizationCards;

PersonalizationCards.propTypes = {
  containerClass: PropTypes.string,
  defaultCardsArray: PropTypes.arrayOf(PropTypes.number),
  cardData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      cardNumber: PropTypes.number.isRequired,
      imgData: PropTypes.object,
      altText: PropTypes.string,
      heading: PropTypes.string,
      subheading: PropTypes.string,
      text: PropTypes.string,
      tertiaryText: PropTypes.string,
      linkId: PropTypes.string,
      linkUrl: PropTypes.string,
      externalLink: PropTypes.bool,
      internalPage: PropTypes.bool,
    })
  )
};
